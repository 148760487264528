<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">{{ surveyTitle }}</span>
      </div>
      <div class="main">
        <ul v-for="(item, index) in QuestionList" :key="index">
          <li class="item">
            <span>{{ index + 1 }}、</span>
            <span v-if="item.questionType == 1" class="item-type-1">单选题</span>
            <span v-if="item.questionType == 2" class="item-type-2">多选题</span>
            <span v-if="item.questionType == 3" class="item-type-3">问答题</span>
            <span>{{ item.questionTitle }}</span>
          </li>
          <li v-if="item.questionType == 1" class="item-answer">
            <template>
              <div v-for="(items, indexs) in item.optionList" :key="indexs" class="item-option">
                <el-radio v-model="radio.cur[index]" :label="items.optionName">{{
                  items.optionName
                }}</el-radio>
              </div>
            </template>
          </li>
          <li v-if="item.questionType == 2" class="item-answer">
            <template>
              <div v-for="(items, indexs) in item.optionList" :key="indexs" class="item-option">
                <el-checkbox :label="items.optionName"></el-checkbox>
              </div>
            </template>
          </li>
        </ul>
      </div>
    </el-card>
  </div>
</template>
<script>
import { preview } from '@/api/examination'
import to from 'await-to'
export default {
  data() {
    return {
      surveyId: this.$route.query.surveyId,
      surveyTitle: this.$route.query.surveyTitle,
      QuestionList: [],
      radio: { cur: [] },
    }
  },
  mounted() {
    this.getQueslist()
  },
  methods: {
    async getQueslist() {
      const [res, err] = await to(preview({ surveyId: this.surveyId }))
      if (err) return this.$message.warning(err.msg)
      this.QuestionList = res.data.questionList
    },
  },
}
</script>
<style scoped>
.main {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
}
.item {
  display: flex;
}
.item-type-1 {
  display: block;
  width: 64px;
  height: 24px;
  background: #8c82f5;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  line-height: 24px;
  margin: 0 5px;
}
.item-type-2 {
  display: block;
  width: 64px;
  height: 24px;
  background: #5db2fa;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  line-height: 24px;
  margin: 0 5px;
}
.item-type-3 {
  display: block;
  width: 64px;
  height: 24px;
  background: #08e1d2;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  line-height: 24px;
  margin: 0 5px;
}
.item-answer {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
}
.item-option {
  margin: 15px 25px;
}
</style>
